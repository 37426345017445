import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import axios from 'axios';
import _ from 'lodash';
import 'slick-carousel/slick/slick.css';

import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../../constants/ActionTypes';
import useUserPermissions from '../../hooks/getUserPermissions';
import { useProduct } from '../../hooks/useProduct';
import { useProductTotals } from '../../hooks/useProductTotals';
import { useModal } from '../../providers/ModalProvider';
import { registerPaths } from '../../providers/TranslatedSlugProvider';
import { handleMultiCartTypeError } from '../../utils/errorHandling';
import { handleMultiCartTypeError2 } from '../../utils/errorHandling2';
import { formatQuantityForView, formatPriceForView as localFormatPrice } from '../../utils/format';
import { useLoader } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';
import User from '../../utils/user';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import QuantitySelector from '../Inputs/QuantitySelector/QuantitySelector';
import Drawer from '../layout/Drawer/Drawer';

import AvailabilitySelector from './Components/AvailabilitySelector';
import CertificateSelector from './Components/CertificateSelector';
import ProductImage from './Components/ProductImage';
import ProductPanel from './Components/ProductPanel';
import Customerservice from './Customerservice';
import RequestSample from './RequestSample/RequestSample';
import Suggestedslider from './Suggestedslider';

// export const productSearchParamsMap = {
//   id: { en: 'id', it: 'articole_id' },
//   configid: { en: 'configid', it: 'configureIdIt' },
// };
const screenAlias = window.screen;
const MIN_QUANTITY_TO_ORDER = 1;
const hexToDegrees = (hex) => {
  // Convert the hexadecimal color value to RGB values
  const r = parseInt(hex.substring(1, 3), 16) / 255;
  const g = parseInt(hex.substring(3, 5), 16) / 255;
  const b = parseInt(hex.substring(5, 7), 16) / 255;

  // Find the maximum and minimum RGB values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the hue based on the maximum and minimum RGB values
  let hue = 0;

  if (max === min) {
    hue = 0;
  } else if (max === r) {
    hue = ((g - b) / (max - min)) % 6;
  } else if (max === g) {
    hue = (b - r) / (max - min) + 2;
  } else {
    hue = (r - g) / (max - min) + 4;
  }

  // Convert the hue to a degree value between 0 and 360
  hue = hue * 60;

  if (hue < 0) {
    hue = hue + 360;
  }

  return hue;
};

const Product = (props) => {
  const { userPermissions } = useUserPermissions({});

  // useTranslateAndReplaceUrlWhenLanguageChanged(props, productSearchParamsMap);
  const [tab, setTab] = useState(0);
  const [filterColor, setFilterColor] = useState([]);
  const [activeColor, setActiveColor] = useState('');
  const [colorname, SetColorname] = useState('');
  const [iscolorMelange, setIsColorMelange] = useState(false);
  const [isproductMelange, setIsProductMelange] = useState(false);
  const [activedetails, setActivedetails] = useState(0);
  const [isZoom, setIsZoom] = useState('');
  const [activeimage, setImage] = useState('');
  const [cID, setCid] = useState(0);
  const [summary, SetSummary] = useState(0);
  const [QTY, _SetQTY] = useState(1);
  const [precise, Setprecise] = useState(0);
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);
  const quantityLoader = useLoader({ height: 82 }, false);
  const params = useParams();
  const loader = useLoader({ height: '20vh' });

  const dispatch = useDispatch();

  const [showRequestSample, setShowRequestSample] = useState(false);

  const {
    isLoading,
    mainProduct: details,
    setMainProduct: setDetails,
    subProduct,
    setSubProduct,
    isLoadingFilter,
    colors: colorList,
  } = useProduct({
    id: params.productId,
    slug: params.family,
  });

  const {
    price,
    unitPrice,
    totalPrice,
    totalSupplement,
    totalSupplement2,
    isAvailableJson: is_available_json,
    setIsAvailableJson: SetIs_available_json,
    globalAvailable,
    available,
    setAvailable: Setavailable,
    isOption: isoption,
    globalCertifications,
    certification,
    setCertification,
    supplement2,
    isLoading: loadingProductTotal,
  } = useProductTotals({
    mainProduct: details,
    colorName: colorname,
    qty: QTY,
  });

  const formatPriceForView = (amount) => localFormatPrice(amount, User.getCurrency(userDataDetail));

  const SetQTY = (q) => {
    if (q < 0) q = MIN_QUANTITY_TO_ORDER;
    _SetQTY(q);
  };

  //const [supplement, setSupplement] = useState(totalSupplement);
  let url;
  let url_scheda_prodotto;
  switch(details?.configid) {
    case '005500':
      url = 'https://www.filmar.it/media/2023_afromood_cruz_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '025000':
      url = 'https://www.filmar.it/media/2023_ares_tribale_rope_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '029500':
      url = 'https://www.filmar.it/media/2023_ares_tribale_rope_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '028000':
      url = 'https://www.filmar.it/media/2023_ares_tribale_rope_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '008000':
      url = 'https://www.filmar.it/media/2024_cottoncashmere_8ed_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '009900':
      url = 'https://www.filmar.it/media/2022_ecoloop_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '010500':
      url = 'https://www.filmar.it/media/2023_x3_zero_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '012500':
      url = 'https://www.filmar.it/media/2023_makocashmere_3ed_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '013000':
      url = 'https://www.filmar.it/media/2023_frescolino_sable_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '014100':
      url = 'https://www.filmar.it/media/2023_marabu_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '014200':
      url = 'https://www.filmar.it/media/prezioso-bon bon_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '014300':
      url = 'https://www.filmar.it/media/2023_snap_data sheet_Filmar.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '014400':
      url = 'https://www.filmar.it/media/2023_hype_macaron_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '014500':
      url = 'https://www.filmar.it/media/2023_steel_maia_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '014600':
      url = 'https://www.filmar.it/media/2023_hype_macaron_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '014900':
      url = 'https://www.filmar.it/media/2024_tipo_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '015200':
      url = 'https://www.filmar.it/media/2023_cube_bloom_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '015300':
      url = 'https://www.filmar.it/media/2023_filoscozia_tricot_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '015400':
      url = 'https://www.filmar.it/media/2023_biojoy_technical sheet.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '015500':
      url = 'https://www.filmar.it/media/2023_gold_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '015700':
      url = 'https://www.filmar.it/media/2023_wool_dream_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
    break;
    case '015800':
      url = 'https://www.filmar.it/media/2023_zeus_datasheet_filmar.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '015900':
      url = 'https://www.filmar.it/media/2023_gala_data sheet_filmar.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '016100':
      url = 'https://www.filmar.it/media/2023_vibe_data sheet_Filmar.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '016200':
      url = 'https://www.filmar.it/media/2023_ghost_data sheet_Filmar.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;   
    //
    case '016300':
      url = 'https://www.filmar.it/media/2023_aral_data sheet_Filmar.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '016400':
      url = 'https://www.filmar.it/media/2023_ploy_data sheet_Filmar.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '016600':
      url = 'https://www.filmar.it/media/prezioso-bon bon_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '017600':
      url = 'https://www.filmar.it/media/2023_magic_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '017700':
      url = 'https://www.filmar.it/media/2023_orfeo_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '017800':
      url = 'https://www.filmar.it/media/2023_audace_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '017900':
      url = 'https://www.filmar.it/media/2023_hiconic_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '001909':
      url = 'https://www.filmar.it/media/c20_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '003500':
      url = 'https://www.filmar.it/media/2023_brillo_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '004500':
      url = 'https://www.filmar.it/media/bollymood_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '006000':
      url = 'https://www.filmar.it/media/cottonstore_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '007000':
      url = 'https://www.filmar.it/media/zero_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '008500':
      url = 'https://www.filmar.it/media/filoscozia_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '009500':
      url = 'https://www.filmar.it/media/artic_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018100':
      url = 'https://www.filmar.it/media/2023_mirage_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018300':
      url = 'https://www.filmar.it/media/2023_ker_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018400':
      url = 'https://www.filmar.it/media/2024_blow_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018500':
      url = 'https://www.filmar.it/media/2023_glossy_2ed_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018600':
      url = 'https://www.filmar.it/media/2024_cottontweed_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018700':
      url = 'https://www.filmar.it/media/2024_irish_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018800':
      url = 'https://www.filmar.it/media/2024_brina_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '018900':
      url = 'https://www.filmar.it/media/2024_blog_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '019100':
      url = 'https://www.filmar.it/media/2024_hitech_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '019500':
      url = 'https://www.filmar.it/media/crazy_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '019700':
      url = 'https://www.filmar.it/media/almacoral_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '019800':
      url = 'https://www.filmar.it/media/biotech_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '019900':
      url = 'https://www.filmar.it/media/zerostretch_digital_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '030000':
      url = 'https://www.filmar.it/media/2023_nilo_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    case '050000':
      url = 'https://www.filmar.it/media/2023_luxor_digitale_SCHEDA.pdf';
      url_scheda_prodotto = 'https://www.filmar.it/media/';
      break;
    default:
      url = '/download/default';
      url_scheda_prodotto = '/download/default';
  }
  const gestione_spese = totalSupplement?.[2];
  /*if (userDataDetail?.view_credit === false) {
    window.location.href = '/';
  }*/

  const handleColorSelect = (color, Name, image, id) => {

    let is_product_melange = false;
    let is_color_melange = false;
    if (details?.nome_articolo?.includes('Melange')) {
      is_product_melange = true;
      setIsProductMelange(true);
    }
    else{
      is_product_melange = false;
      setIsProductMelange(true);
    }
    
    details?.colori.forEach(element => {
      if (element.id == id) {
        if (element.mel == 'MEL') {
          is_color_melange = true;
          setIsColorMelange(true);
        } else {
          is_color_melange = false;
          setIsColorMelange(false);
        }
      }
    });
    
    if (is_product_melange && !is_color_melange) {
      modal.showAttention({
        title: t('popup.warning-product-color-select'),
        subtitle:  t('popup.subtitle-product-melange-color-not-melange'),
        allowOutsideClick: false,
      });
      setImage('');
      setCid(0);
      setActiveColor('');
      SetColorname('');
    }
    else if(!is_product_melange && is_color_melange) {
      modal.showAttention({
        title: t('popup.warning-product-color-select'),
        subtitle:  t('popup.subtitle-product-not-melange-color-melange'),
        allowOutsideClick: false,
      });
      setImage('');
      setCid(0);
      setActiveColor('');
      SetColorname('');
    }
    else{
      setImage(image);
      setCid(id);
      setActiveColor(color);
      SetColorname(Name);
    }
  };

  const Richiedi_rocche_preciseChange = () => {
    if (precise == 0) {
      Setprecise(1);
    } else {
      Setprecise(0);
    }
  };

  const closeminicaet = () => {
    SetSummary(0);
    SetQTY(MIN_QUANTITY_TO_ORDER);
    Setprecise(0);
    SetIs_available_json([]);
  };

  const colorDisponibili = (e) => {
    e.stopPropagation();
    document.getElementById('accordion_info_color').classList.toggle('active');
  };

  const handleAccordation = (id, id_next, is_open = 'close') => {
    
    if (id == 'accordation_1') {
      let accordation_12 = document?.getElementById('accordation_12');
      accordation_12.classList.toggle('header_stiky');
    }

    let component = document?.getElementById(id);
    var acc = document.getElementById(id_next);

    if (is_open == 'open') {
      acc.classList.add('arrow_rottate');
      component.style.maxHeight = component.scrollHeight + 500 + 'px';
    } else {
      acc.classList.toggle('arrow_rottate');
      acc.style.maxHeight = 500 + 'px';

      if (component.style.maxHeight) {
        component.style.maxHeight = null;
      } else {
        component.style.maxHeight = component.scrollHeight + 50 + 'px';
      }
    }
  };
  const modal = useModal();

  const openMiniCart = () => {
      
    if (price == 0) {
      modal.showAttention({
        title: t('popup.errorValidation'),
        subtitle: (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: t('popup.messagge_error_product_with_price_zero'),
              }}
            />
          </>
        ),
        confirmButtonText: 'OK' 
      });
    }
    else{
      if (cID === 0) {
        modal.showAttention({ title: t('Si prega di selezionare il colore'), confirmButtonText: 'OK' });
      } else {
        SetSummary(cID);
      }
    }
      
    
  };
  
  const addtoCart = () => {

    //const supplement1 = getSupplement1();
    // Swal.fire({
    //   title: 'Adding to Cart...',
    //   text: 'Please wait while we add the product to your cart',
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading();
    //   },
    //   showCancelButton: false,
    //   showConfirmButton: false,
    // });

    modal.showLoading({
      // title: 'Aggiungo al carrello...',
      title: t('popup.reorderAdding'), //'Adding product to the cart',
      // subtitle:  //'Per favore attendi mentre aggiungiamo il prodotto al carrello.',
      allowOutsideClick: false,
    });

    axios
      .post('/v1/add-to-cart', {
        config_id: details?.configid,
        color_id: colorname,
        product_name: details?.p_name,
        product_price: price,
        suppliment: totalSupplement?.[0],
        CERTIFICATESGROUP_CGK_19052: details.CERTIFICATESGROUP_CGK_19052,
        certificate: certification,
        suppliment2: supplement2,
        quantity: QTY,
        client_id: userDataDetail.id,
        is_available_json: JSON.stringify({
          gestione_spese: gestione_spese,
          suppliment: totalSupplement?.[0],
          suppliment2: supplement2,
          available: available,
          isprecise: precise,
          isoption: isoption,
          ItemId: details?.codice_articolo,
          is_available_json: is_available_json,
        }),
        precise_integer: precise,
        avelphysical: available,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: response.data.result.data?.cartQuantity || 0,
        });
        modal.showSuccess({
          subtitle: t('popup.productAdded'),
          confirmButtonText: t('ok_got_it'),
          onConfirm: () => closeminicaet(),
        });
      })
      .catch((error) => {
        
        if (error?.response?.data?.result?.type === 'multicart_type_error') {
          handleMultiCartTypeError(userDataDetail, modal, closeminicaet, dispatch, t);
        }
        else if(error?.response?.data?.result?.type === 'message_error_campionautura_presente'){
          handleMultiCartTypeError2(userDataDetail, modal, closeminicaet, dispatch, t);
        }
        else {
          alert(error?.response?.data?.message || 'Something went wrong');
        }
      });
  };

  const updateQTY = (set_qty) => {
    SetQTY(parseFloat(set_qty));
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    var acc = document.getElementsByClassName('accordion_title_copy');
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active_accordation');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }
  }, [tab]);

  if (isLoading) {
    return (
      <div className="loader_wrapper">
        <div className="loader"></div>
      </div>
    );
  }

  var product_slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 766,
        settings: {
          dots: false,
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="main_content product_detail">
        <div className="product_section">
          <div className="product_wrappper container">
            <div className="product_title mobile_section">
              <span>{t('collezione')}</span>
              <h2>{details?.p_name}</h2>
            </div>

            <div className="product_slider product_media">
              {!activeColor && (
                <Slider {...product_slider}>
                  {details?.product_images?.map((id) => (
                    <div key={id} className="product_slider_block">
                      <div className="product_slider_img">
                        <img
                          src={id}
                          className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                          alt="product_image"
                          style={{ filter: `hue-rotate(${Math.floor(hexToDegrees(activeColor) || 0) + 'deg'})` }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/images/artic_06.jpg';
                          }}
                        />
                        {!isZoom ? (
                          <p className="zoom" onClick={() => setIsZoom(1)}>
                            Zoom{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        ) : (
                          <p className="zoom" onClick={() => setIsZoom(0)}>
                            Zoom Out{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                  {details?.product_cc_images?.map((id) => (
                    <div key={id} className="product_slider_block">
                      <div className="product_slider_img">
                        <img
                          src={id}
                          className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                          alt="product_image"
                          style={{ filter: `hue-rotate(${Math.floor(hexToDegrees(activeColor)) + 'deg'})` }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/images/artic_06.jpg';
                          }}
                        />
                        {!isZoom ? (
                          <p className="zoom" onClick={() => setIsZoom(1)}>
                            Zoom{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        ) : (
                          <p className="zoom" onClick={() => setIsZoom(0)}>
                            Zoom Out{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              {activeColor && (
                <Slider {...product_slider}>
                  {details?.product_t_images?.map((id) => (
                    <div key={id} className="product_slider_block">
                      {activeimage == '' ? (
                        <div className="product_slider_img" style={{ backgroundColor: `${activeColor}` }}>
                          <img
                            style={{ mixBlendMode: 'soft-light' }}
                            src={id}
                            className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                            // className={`desktop_img ${isZoom ? '' : ''}`}
                            alt="product_image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/images/artic_06.jpg';
                            }}
                          />
                          {!isZoom ? (
                            <p className="zoom" onClick={() => setIsZoom(1)}>
                              Zoom{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          ) : (
                            <p className="zoom" onClick={() => setIsZoom(0)}>
                              Zoom Out{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className="product_slider_img">
                          <img
                            style={{ mixBlendMode: 'soft-light' }}
                            src={activeimage}
                            className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                            // className={`desktop_img ${isZoom ? '' : ''}`}
                            alt="product_image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/images/artic_06.jpg';
                            }}
                          />
                          {!isZoom ? (
                            <p className="zoom" onClick={() => setIsZoom(1)}>
                              Zoom{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          ) : (
                            <p className="zoom" onClick={() => setIsZoom(0)}>
                              Zoom Out{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </Slider>
              )}
        <p className='testo-info-prodotto-immmagine'>{t('immagini')}</p>
        <div className="tab_content details product-features product-info-dettagli info-p-desktop">
          <div id="dettagli" className="accordion_info">
            {/*<h3>{t('utilizzo')}</h3>
            <div className="accordion_content" >
              <p>{details?.description_it}</p>
            </div>*/}
          </div>
          <div className="accordion_info">
            <h3>{t('caratteristiche')}</h3>
            <div className="accordion_content" >
              <ul className="characteristics">
              {details?.FilterDetail?.map((id, index) => {
                if (id.type === 'caratteristiche' && id?.detail?.icon_url) {
                  return (
                    <li key={index}>
                      <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                    </li>
                  );
                }
                return null;
              })}
              </ul>
            </div>
          </div>
          <div className="accordion_info">
            <h3>{t('certificazioni')}</h3>
            <div className="accordion_content">
              <ul className="certifications">
                {details?.FilterDetail?.map((id, index) => {
                  return id.type == 'certificazioni' ? (
                    <>
                      {console.log(id?.detail)}
                      <li key={index}>
                        <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                      </li>
                    </>
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="accordion_info">
            <h3>{t('cura_del_prodotto')}</h3>
            <div className="accordion_content">
              <ul className="body_care">
                {details?.FilterDetail?.map((id, index) => {
                  if (id.type === 'cura_del_prodotto' && id?.detail?.icon_url) {
                    return (
                      <li key={index}>
                        <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
          <div id="cartella-colori" className="accordion_info">
            <h3>{t('scarica_cartelle_digitali')}</h3>
            <div className="accordion_content">
              <ul className="bdigital_folder">
              <div class="download-links">
                  {details?.p_url ? (
                    <a className="download-link" target="_blank" href={details?.p_url || '#'}>
                      {t('Cartella colore')}
                    </a>
                  ) : (
                    ''
                  )}
                  <a href="https://www.filmar.it/media/2024_book_ss26_digital.pdf" target="_blank" class="download-link">{t('General book 2026')}</a>
                  <a href="https://www.filmar.it/media/2024_book_transeasonal_easycare_collection_digital.pdf" target="_blank" class="download-link">{t('Transeasonal 2024-2025')}</a>
              </div>
              </ul>
            </div>
          </div>
          <div class="action-buttons">
              {
                (() => {
                  url_scheda_prodotto ='/download/default';
                  if(url_scheda_prodotto !== '/download/default'){
                      return (
                        <a href={url_scheda_prodotto} className="action-btn" download>
                          <span>{t('visualizza_scheda_prodotto')}</span>
                          <i class="fa-regular fa-file"></i>
                        </a>
                      );
                    }else{
                      return null;
                    }
                })()
              }
              {
                (() => {
                  if(url !== '/download/default'){
                      return (
                        <a href={url} className="action-btn" download>
                          <span>{t('scarica_scheda_tecnica')}</span>
                          <i class="fa-solid fa-arrow-down"></i>
                        </a>
                      );
                    }else{
                      return null;
                    }
                })()
              }
          </div>
        </div>
            </div>

            <div className="product_info">
              <div className="product_title desktop_section">
                <span>{t('collezione')}</span>
                <h2>{_.capitalize(details?.p_name)}</h2>
                <div className="item_color">
                  <p className="count">
                    <>
                    <span>+{details?.colori_in_cartella}</span>
                    </>
                  </p>
                  <p className="color">
                    <span className="red" />
                    <span className="yellow" />
                    <span className="blue" />
                  </p>
                </div>
                <div className="btn-download-scheda-tecnica">
                  {
                    (() => {
                      if(url !== '/download/default'){
                        return (
                          <a href={url} className="download-btn" download>
                            {console.log(details?.configid)}
                            <span>{t('scarica_scheda_tecnica')}</span>
                            <i className="fa-solid fa-download"></i>
                          </a>
                        );
                      }else{
                        return null;
                      }
                    })()
                  }
                </div>

                {/*<div className="sheet_link">*/}
                {/*  <a href="#">{t("visualizza_scheda_tecnica")}</a>*/}
                {/*</div>*/}
              </div>
              <ProductPanel
                {...{
                  setShowRequestSample,
                  setTab,
                  details,
                  tab,
                  screenAlias,
                  handleAccordation,
                  subProduct,
                  isLoadingFilter,
                  setActivedetails,
                  openMiniCart,
                  colorname,
                  colorDisponibili,
                  setFilterColor,
                  colorList,
                  filterColor,
                  handleColorSelect,
                  activeColor,
                  cID,
                  userDataDetail,
                  setSubProduct,
                  activedetails,
                }}
              />

              <div className="order_action desktop_section">
                {userPermissions.canAddNormalProductToCart ? <p>{t('per_ordinire_il_seguente')}</p> : null}
                <div className="button-container">
                    {userDataDetail?.id === undefined ? (
                        <div className="add-to-cart">
                        <span class="cart-icon"><i class="fas fa-shopping-cart"></i></span>
                        <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
                          {t('Aggiungi_al_carrello')}
                        </Link>
                        </div>
                    ) : userPermissions.canAddNormalProductToCart ? (
                      agentDataDetail && agentDataDetail?.id === userDataDetail?.id ? (
                        <Link className="no-padding" to="/dashboard-list">
                          <PrimaryButton fullWidth>{t('agent.select-client')}</PrimaryButton>
                        </Link>
                      ) : (
                        <>
                        <div id="add-to-cart-logged">
                        <PrimaryButton fullWidth onClick={openMiniCart}>
                        <span class="cart-icon"><i class="fas fa-shopping-cart"></i></span>
                          <span>{t('Aggiungi_al_carrello')}</span>
                        </PrimaryButton>
                        </div>
                        </>
                      )
                    ) : null}
                  
                  <div id="campionatura-btn-logged" className="secondary-buttons">
                    {userDataDetail?.id && !(agentDataDetail && agentDataDetail?.id === userDataDetail?.id) ? (
                        <PrimaryButton fullWidth onClick={() => setShowRequestSample(true)} id="btn-request-sample-logged">
                          {t('richiedi_campionatura')}
                        </PrimaryButton>
                    ) : (
                      <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')} className="secondary-btn">
                        {t('richiedi_campionatura')}
                      </Link>
                    )}
                    <a href="#richiedi-info-collezione" className="secondary-btn">
                      {t('richiedi_informazioni_aggiuntive')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Customerservice
            details={details}
            config_id={details?.configid}
            cID={cID}
            product_name={details?.p_name}
            client_id={userDataDetail?.id}
          />
          <div className="seller_section">
            <div className="seller_wrapper container">
              <div className="title">
                <ul>
                  <li className="active">
                    <a href="#">{t('prodotti_correlati')}</a>
                  </li>
                </ul>
              </div>

              <div className="seller_content w-100">
                <Suggestedslider details={details} />
                <div className="container more_section">
                <div className="link">
                  {loader.asJsx || (
                    <a href={`/${i18n.language}/prodotti`}>
                      <button>
                        {t('vai_ai_filati')}{' '}
                      </button>
                    </a>
                  )}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showRequestSample ? (
        <RequestSample
          onClose={() => setShowRequestSample(false)}
          details={details}
          productsForFondo={details?.sub_produts || []}
        />
      ) : null}

      {cID >= 1 && summary >= 1 ? (
        <Drawer
          onClose={closeminicaet}
          title={t('Riepilogo')}
          content={
            <div className="product_summary">
              <div className="product_details">
                <div className="product_img_name">
                  <ProductImage
                    activeimage={activeimage}
                    activeColor={activeColor}
                    product_t_images={details?.product_t_images || []}
                    product_image={details.product_image}
                  />
                  <div>
                    <h4 className="product_name">
                      {_.capitalize(details.p_name)} {subProduct?.is_mel == 'M' ? ' mèlange' : ''}
                    </h4>
                    <p>{details.composition}</p>
                    <p>
                      {details?.fibra_1} {details?.perc_1?.replaceAll('.0', '')}
                      {details.perc_1 ? '%' : ''}
                      {details.fibra_2 ? ' - ' : ''}
                      {details?.fibra_2}{' '}
                      {details?.perc_2 != 0 ? (
                        <>
                          {details?.perc_2?.replaceAll('.0', '')}
                          {details.perc_2 ? '% ' : ''}
                        </>
                      ) : (
                        ''
                      )}
                      {details.fibra_3 ? ' - ' : ''}
                      {details?.fibra_3}{' '}
                      {details?.perc_3 != 0 ? (
                        <>
                          {details?.perc_3?.replaceAll('.0', '')}
                          {details.perc_3 ? '% ' : ''}
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                    <p>
                      <span>Nm:</span>
                      <span> {details.nm}</span>
                    </p>
                    <p>
                      <span>Ne:</span>
                      <span> {details.ne}</span>
                    </p>
                    <p>
                      <span>{t('finezza')}:</span>
                      <span>{details.finezza} </span>
                    </p>
                    <p>
                      <span>{t('color')}:</span>
                      <span> {colorname}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h5 className="details_title">{t('Definisci_quantita')}</h5>
                  <p>{t('Scegli_kg')}</p>
                </div>
                <div className="product_qty">
                  <h4>Kg</h4>
                  <QuantitySelector QTY={QTY} updateQTY={updateQTY} />
                </div>

                {userDataDetail.PRECISECONES_CGK_2014 == 1 ? (
                  <div className="request_precise">
                    <label>
                      <input onChange={(e) => Richiedi_rocche_preciseChange()} type="checkbox" />
                      <span className="checkbox" />
                    </label>
                    <div>
                      <span>{t('Rocche_precise')}</span>

                      {precise >= 1 ? (
                        <>
                          {/*<h5>Numero rocche</h5>
                          <div className="product_qty">
                            <p>
                              <span
                                className="minus"
                                onClick={(e) => {
                                  if (precise >= 2) Setprecise(precise - 1);
                                }}
                              >
                                -
                              </span>
                              <input
                                key={'asdadd'}
                                value={precise}
                                onChange={(e) => {
                                  e.target.value <= 99999 && e.target.value >= 1
                                    ? Setprecise(e.target.value)
                                    : Setprecise(1);
                                }}
                                type="number"
                              />
                              <span
                                className="add"
                                onClick={(e) => {
                                  Setprecise(precise + 1);
                                }}
                              >
                                +
                              </span>{' '}
                            </p>
                          </div>*/}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <AvailabilitySelector
                globalAvailable={globalAvailable}
                QTY={QTY}
                available={available}
                Setavailable={Setavailable}
              />
              <CertificateSelector
                globalCertifications={globalCertifications}
                certification={certification}
                setCertification={setCertification}
              />
              <div className="required_fields">
                <p>{t('campi_obbligatori')}</p>
              </div>
              <div className="total_price">
                <div>
                  <p>{t('prezzo_unitario')}</p>
                  <p>{formatPriceForView(unitPrice)}</p>
                </div>
                <div>
                  <p>{t('Quantità')}</p>
                  <p>{formatQuantityForView(QTY)}</p>
                </div>
                {/* certificatprice will be needed in the future; DO NOT REMOVE IT */}
                {/* {certificat ? (
                  <div>
                    <p>Certificazione {certificat}</p>
                    <p>{formatPriceForView(certificatprice)}</p>
                  </div>
                ) : (
                  ''
                )} */}

                {totalSupplement?.[0] ? (
                  <div>
                    <p>{t('Supplemento')}</p>
                    {loadingProductTotal ? (
                      <div className="loader" style={{ width: '18px', height: '18px', borderWidth: '2px' }}></div>
                    ) : (
                      <p>{formatPriceForView(totalSupplement?.[0])}</p>
                    )}
                  </div>
                ) : (
                  ''
                )}
                <div>
                  <h4>{t('total')}</h4>
                  {loadingProductTotal ? (
                    <div className="loader" style={{ width: '18px', height: '18px', borderWidth: '2px' }}></div>
                  ) : (
                    <h4>{formatPriceForView(totalPrice)}</h4>
                  )}
                  {/* certificatprice will be needed in the future; DO NOT REMOVE IT */}
                  {/* <h4>{formatPriceForView(price * QTY + (certificatprice + suppliment))}</h4> */}
                </div>
                <p>{t('spese_spedizione')}</p>
              </div>
              <div className="add_cart_btn active">
                {price >= 1 && QTY >= 1 && available != 'option' ? (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      addtoCart();
                    }}
                  >
                    {t('Aggiungi_al_carrello')}
                  </a>
                ) : (
                  quantityLoader.asJsx || (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      href="#"
                      style={{ cursor: 'default', opacity: 0.5 }}
                    >
                      {t('Aggiungi_al_carrello')}
                    </a>
                  )
                )}
              </div>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default Product;