import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useUserPermissions from '../../../hooks/getUserPermissions';
import { registerPaths } from '../../../providers/TranslatedSlugProvider';
import { getTranslated } from '../../../utils/translations';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
//import Colorpicker from '../Colorpicker';

import Colorshow from './Colorshow';

export default function ProductPanel({
  setShowRequestSample,
  setTab,
  details,
  tab,
  screen,
  handleAccordation,
  subProduct,
  isLoadingFilter,
  setActivedetails,
  openMiniCart,
  colorname,
  colorDisponibili,
  setFilterColor,
  colorList,
  filterColor,
  handleColorSelect,
  activeColor,
  cID,
  userDataDetail,
  setSubProduct,
  activedetails,
}) {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useUserPermissions({});
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);
  const screenAlias = window.screen;
  return (
    <div className="product_tab">
      <span className={`tab_title ${tab == 0 && 'active'}`} onClick={() => setTab(0)}>
        {t('configura_prodotto')}
      </span>
      {(tab == 0 || screenAlias.width <= 600) && (
        <div className="tab_content configure">
          <div className="accordion_info">
            <div className="accordion_title" id="test_1" onClick={() => handleAccordation('accordation_1', 'test_1')}>
              {subProduct ? (
                <p className="p_details_">
                  {t('articolo')}:{' '}
                  <b>
                    {subProduct?.nome_articolo}
                    {subProduct?.is_mel == 'M' ? ' mèlange' : ''} | {getTranslated(i18n,subProduct,{ en: 'productname_en', it: 'productname_it ' },'productname_it')} | {'NM ' + subProduct?.nm} |{' '}
                    {'NE ' + subProduct?.ne} | {t('Finezza_info')} {subProduct?.finezza || 'NA'} | {subProduct?.servizio || 'NA'}{' '}
                  </b>
                </p>
              ) : (
                <h3>{t('seleziona_articolo')}</h3>
              )}

              <span>
                {details?.sub_produts?.length} {t('articoli_disponibili')}
              </span>
            </div>
            <div className="accordion_content" id="accordation_1">
              <div className="select_section custom-scrollbar">
                <div className="select_header " id="accordation_12">
                  <div className="select_row">
                    <div className="select_title name">{t('Nome')}</div>
                    <div className="select_title composition">{t('composizione')}</div>
                    <div className="select_title">{t('nm')}</div>
                    <div className="select_title">{t('ne')}</div>
                    <div className="select_title finesse">{t('finezza')}</div>
                    <div className="select_title finesse">{t('servizio')}</div>
                  </div>
                </div>
                <div className="select_body header_stiky_1">
                  {details?.sub_produts?.map((singleProduct, idx) => (
                    <div
                      key={singleProduct?.configid + idx}
                      onClick={() => {
                        setSubProduct(singleProduct);
                        handleAccordation('accordation_2', 'test_2', 'open');
                        handleAccordation('accordation_1', 'test_1');
                      }}
                      className={`select_row ${singleProduct?.id === subProduct?.id && 'active_selecteed_color'}`}
                    >
                      <div className="select_column name" data-label="Nome">
                        {singleProduct?.nome_articolo} {singleProduct?.is_mel == 'M' ? ' mèlange' : ''}
                      </div>
                      <div className="select_column composition" data-label="Composizione">
                        {getTranslated(
                          i18n,
                          singleProduct,
                          { en: 'productname_en', it: 'productname_it ' },
                          'productname_it'
                        )}
                      </div>

                      <div className="select_column" data-label="Nm">
                        {singleProduct?.nm}
                      </div>
                      <div className="select_column" data-label="Ne">
                        {singleProduct?.ne}
                      </div>
                      <div className="select_column finesse" data-label="Fin">
                        {singleProduct?.finezza || 'NA'}
                      </div>
                      <div className="select_column finesse last_flex_services" data-label="Fin">
                        {singleProduct?.servizio || 'NA'}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="accordion_info colore" id="accordion_info_color">
            <div className="accordion_title " id="test_2" onClick={() => handleAccordation('accordation_2', 'test_2')}>
              <h3>
                {' '}
                {colorname ? (
                  <>
                    {t('color')}: {colorname}
                  </>
                ) : (
                  t('seleziona_colore')
                )}
              </h3>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="disponibili_span">
                  {subProduct ? subProduct?.colori_in_cartella + ' ' + t('colori_disponibili') : ''}
                </span>
                <div className="item_color">
                  <p className="color">
                    <span className="red" />
                    <span className="yellow" />
                    <span className="blue" />
                  </p>
                </div>
                <div className="more_color" style={{ display: 'none' }}>
                  <div className="more_img">
                    <img onClick={colorDisponibili} src="/images/product/color_expand.png" alt="color_expand" />
                    {/*<Colorpicker setFilterColor={setFilterColor} color={colorList} />*/}

                  </div>
                </div>
              </div>
            </div>

            <div className="accordion_content" id="accordation_2">
              {!isLoadingFilter ? (
                <>
                  <Colorshow
                    filterColor={filterColor}
                    colorList={colorList}
                    handleColorSelect={handleColorSelect}
                    activeColor={activeColor}
                    cid={cID}
                    details={details}
                  />{' '}
                </>
              ) : (
                <div className="filter_color_loader">
                  <div class="loader"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <span className={` tab_title ${tab == 1 && 'active'}`} onClick={() => setTab(0)}>
        <a href="#dettagli" >{t('dettagli')}</a>
      </span>
      {(tab == 1 || screenAlias.width <= 600) && (
        <></>
      )}

      <span className={`tab_title ${tab == 3 && 'active'}`} onClick={() => setTab(0)}>
        <a href="#cartella-colori" >{t('Cartella colore')}</a>
      </span>
      {/*(tab == 3 || screenAlias.width <= 600) && (
        <div className="tab_content details">
          <div className="accordion_info">
            <div className="links new_link">
              <a target="_blank" href={'https://filmar.it/media/2023_book_ss24_digitale_web.pdf'} download>
                {t('General book 2025')}
              </a>
            </div>
            <div className="links new_link">
              <a target="_blank" href={'https://www.filmar.it/media/2023_book_aw_24-25_digital_web.pdf'} download>
                {t('Autumn - Winter 2024-2025')}
              </a>
            </div>
            <div className="links new_link">
              
              {details?.p_url ? (
                <a target="_blank" href={details?.p_url || '#'} download>
                  {t('Cartella colore')}
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )*/}

      {['COTTON STORE', 'FILOSCOZIA'].some((name) => details?.p_name?.includes(name)) ? (
        <>
          <span className={`tab_title ${tab == 2 && 'active'}`} onClick={() => setTab(2)}>
            {t('Digital Product Passport')}
          </span>
          {(tab == 2 || screenAlias.width <= 600) && (
            <div className="tab_content digital_product_passport">
              {['COTTON STORE'].some((name) => details?.p_name?.includes(name)) ? (
                <>
                  <div className="links new_link">
                    <a target="_blank" href="https://app.sustainablebrandplatform.com/Combed.html">
                      COTTONSTORE - 30/1 COLORI NORMALI - Combed Melange
                    </a>
                  </div>
                  <div className="links new_link">
                    <a target="_blank" href="https://app.sustainablebrandplatform.com/Dyed_Yarn.html">
                      COTTONSTORE - 30/1 COLORI MELANGE
                    </a>
                  </div>
                </>
              ) : null}
              {['FILOSCOZIA'].some((name) => details?.p_name?.includes(name)) ? (
                <>
                  <div className="links new_link">
                    <a target="_blank" href="https://app.sustainablebrandplatform.com/Mercerized.html ">
                      FILOSCOZIA 60/2 - Mercerized
                    </a>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </>
      ) : (
        ''
      )}

      <div className="order_action mobile_section">
        <div className="button-container">
          <div className="secondary-buttons">
            {userPermissions.canAddNormalProductToCart ? <p>{t('per_ordinire_il_seguente')}</p> : null}
            {userDataDetail?.id && !(agentDataDetail && agentDataDetail?.id === userDataDetail?.id) ? (
              <div className="req_link">
                <PrimaryButton fullWidth onClick={() => setShowRequestSample(true)}>
                  {t('richiedi_campionatura')}
                </PrimaryButton>
              </div>  
            ) : (
              <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')} className="secondary-btn">
                {t('richiedi_campionatura')}
              </Link>
            )}
          </div>
            <div className="login_action">
              <div className="add-to-cart">
              <span class="cart-icon"><i class="fas fa-shopping-cart"></i></span>
              {userDataDetail?.id == undefined ? (
                <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
                  {t('Aggiungi_al_carrello')}
                </Link>
              ) : userPermissions.canAddNormalProductToCart ? (
                agentDataDetail && agentDataDetail?.id === userDataDetail?.id ? (
                  <PrimaryButton fullWidth>{t('agent.select-client')}</PrimaryButton>
                ) : (
                  <PrimaryButton fullWidth onClick={openMiniCart}>
                    {t('Aggiungi_al_carrello')}
                  </PrimaryButton>
                )
              ) : null}
              </div>
            </div>
          
        </div>
      </div>
      <div className="tab_content details product-features product-info-dettagli info-p-mobile">
          <div id="dettagli" className="accordion_info">
            <h3>{t('utilizzo')}</h3>
            <div className="accordion_content" >
              <p>{details?.description_it}</p>
            </div>
          </div>
          <div className="accordion_info">
            <h3>{t('caratteristiche')}</h3>
            <div className="accordion_content" >
              <ul className="characteristics">
                {details?.FilterDetail?.map((id, index) => {
                  return id.type == 'caratteristiche' ? (
                    <li key={index}>
                      <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                    </li>
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="accordion_info">
            <h3>{t('certificazioni')}</h3>
            <div className="accordion_content">
              <ul className="certifications">
                {details?.FilterDetail?.map((id, index) => {
                  return id.type == 'certificazioni' ? (
                    <>
                      {console.log(id?.detail)}
                      <li key={index}>
                        <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                      </li>
                    </>
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="accordion_info">
            <h3>{t('cura_del_prodotto')}</h3>
            <div className="accordion_content">
              <ul className="body_care">
                {details?.FilterDetail?.map((id, index) => {
                  return id.type == 'cura_del_prodotto' ? (
                    <li key={index}>
                      <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                    </li>
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>
          </div>
          <div id="cartella-colori" className="accordion_info">
            <h3>{t('scarica_cartelle_digitali')}</h3>
            <div className="accordion_content">
              <ul className="bdigital_folder">
              <div class="download-links">
                  {details?.p_url ? (
                    <a className="download-link" target="_blank" href={details?.p_url || '#'}>
                      {t('Cartella colore')}
                    </a>
                  ) : (
                    ''
                  )}
                  <a href="https://www.filmar.it/media/2024_book_ss26_digital.pdf" target="_blank" class="download-link">{t('General book 2026')}</a>
                  <a href="https://www.filmar.it/media/2024_book_transeasonal_easycare_collection_digital.pdf" target="_blank" class="download-link">{t('Transeasonal 2024-2025')}</a>
              </div>
              </ul>
            </div>
          </div>
          {<div class="action-buttons">
              <button class="action-btn">
                <span>{t('visualizza_scheda_prodotto')}</span>
                <i class="fa-regular fa-file"></i>
              </button>
              <button class="action-btn">
                <span>{t('scarica_scheda_tecnica')}</span>
                <i class="fa-solid fa-arrow-down"></i>
              </button>
          </div>}
        </div>
    </div>
  );
}