import { React } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useUserTicketList } from '../../../../hooks/useUserTicketList';
import PrimaryButton from '../../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../Buttons/SecondaryButton/SecondaryButton';
import Loader from '../../../Loader';
import Pagination from '../../Pagination';

import './styles.scss';

const TicketsList = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);
  const { t } = useTranslation();
  const { isLoading, rows, page, setPage, filters, updateFilter, perPage, updatePerPage, resetFilter } =
    useUserTicketList({});

  const getTranslatedState = (approval) => {
    if (approval == 1) return t(`Approvato`);
    if (approval == 2) return t(`Respinto`);
    return t(`Nuovo`); //0
  };

  const getClosedState = (closed) => {
    if (closed == 'Sì') return t(`Sì`);
    return t(`No`); //0
  };

  return (
    <div className="tickets-list">
      <div className="header">
        {dataUser?.create_ticket && (
          <a href={'/ticket-create/' + dataUser.id}>
            <PrimaryButton fullWidth>{t('create_ticket')}</PrimaryButton>
          </a>
        )}
      </div>
      <div className="content_top ticket-filter">
        <div className="left">
          <div className="search_field">
            <input
              type="search"
              name="search"
              placeholder="Cerca ticket"
              value={filters.ticketId}
              onChange={(event) => {
                setPage(1);
                updateFilter('ticketId', event.target.value);
              }}
            />
          </div>
          <div className="search_field date">
            <span>{t('common.date_from')}:</span>
            <input
              type="date"
              name="startDate"
              placeholder="Start date"
              value={filters.startDate}
              onChange={(event) => {
                setPage(1);
                updateFilter('startDate', event.target.value);
              }}
            />
          </div>
          <div className="search_field date">
            <span>{t('common.date_to')}:</span>
            <input
              type="date"
              name="endDate"
              placeholder="End date"
              value={filters.endDate}
              onChange={(event) => {
                setPage(1);
                updateFilter('endDate', event.target.value);
              }}
            />
          </div>
          <div className="search_field">
            <select
              name="status"
              id="status"
              value={filters.status}
              onChange={(event) => {
                setPage(1);
                updateFilter('status', event.target.value);
              }}
            >
              <option value="" selected disabled>
                Status
              </option>
              <option value="Approvato">Approvato</option>
              <option value="Respinto">Respinto</option>
              <option value="Nuovo">Nuovo</option>
            </select>
          </div>
          <div className="search_field date">
            <SecondaryButton onClick={resetFilter}>{t('common.filter_reset')}</SecondaryButton>
          </div>
        </div>
        <div className="right">
          <div className="per-page">
            <span>Numero ticket</span>
            <select
              name="per_page"
              id="per_page"
              value={perPage}
              onChange={(event) => updatePerPage(event.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="content_data" id="tickets-table">
            <div className="ticket_table_header">
              <div className="ticket_table_row">
                <div className="ticket_table_title number">{t('ticket_number')}</div>
                <div className="ticket_table_title">{t('date')}</div>
                <div className="ticket_table_title">{t('Utente')}</div>
                <div className="ticket_table_title">{t('order_number')}</div>
                <div className="ticket_table_title stato">{t('closed')}</div>
                <div className="ticket_table_title stato">{t('Stato')}</div>
              </div>
            </div>
            <div className="ticket_table_body">
              {rows.length > 0 &&
                rows.map((item, index) => {
                  let className =
                    'ticket_table_column' +
                    ' stato ' +
                    (item.INVENTNONCONFORMANCEAPPROVAL === 1
                      ? 'approval'
                      : item.INVENTNONCONFORMANCEAPPROVAL === 2
                        ? 'rejected'
                        : 'new');
                  let classNameClosed =
                    'ticket_table_column' + ' stato ' + (item.CLOSED === 'Sì' ? 'resolved' : 'rejected');
                  return (
                    <div key={index} className="ticket_table_row">
                      <div className="ticket_table_column number" data-label="Numero ticket">
                        <Link
                          to={
                            '/ticket-detail/' +
                            item.INVENTDIMID +
                            '/' +
                            item.INVENTNONCONFORMANCEID +
                            '/' +
                            getTranslatedState(item.INVENTNONCONFORMANCEAPPROVAL)
                          }
                        >
                          #{item.INVENTNONCONFORMANCEID}
                        </Link>
                      </div>
                      <div className="ticket_table_column" data-label="Data">
                        <Moment date={item.NONCONFORMANCEDATE} format="DD/MM/YYYY" />
                      </div>
                      <div className="ticket_table_column" data-label="Utente">
                        master
                      </div>
                      <div className="ticket_table_column" data-label="Numero ordine">
                        #{item.INVENTREFID}
                      </div>
                      <div className={classNameClosed} data-label="Chiuso">
                        {getClosedState(item.CLOSED)}
                      </div>
                      <div className={className} data-label="Stato">
                        {item.INVENTNONCONFORMANCEAPPROVAL}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {!(page === 1 && rows.length < perPage) ? (
            <Pagination
              className="pagination-bar"
              currentPage={page - 1} // Why should we add 1?
              totalCount={rows.length < perPage ? perPage * (page - 1) + perPage : perPage * page + 1}
              pageSize={perPage}
              onPageChange={(page) => setPage(page + 1)} // Why should we minus 1?
              onPageClickScrollHref="tickets-table"
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default TicketsList;