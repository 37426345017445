import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'axios';
import swal from 'sweetalert';

import { useModal } from '../../../providers/ModalProvider';
import ContactForm from '../../ContactForm';

import SimpleMap from './map';

const Contact = () => {
  const [page, Setpage] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const [socialMedia, SetsocialMedia] = useState([]);

  const q = searchParams.get('q');
  const src = searchParams.get('slug');
  const f = searchParams.get('f');

  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');

    axios.get('/v1/social-media').then((response) => {
      if (isNaN(response.data.result.data)) SetsocialMedia([...response.data.result.data]);
    });
  }, []);

  return (
    <div class="main_content">
      <div class="bg_contatti">
        <img src="/admin/images/filmar_chi_siamo_banner_1736952578.PNG"></img>
      </div>
      <div class="contact_section container">
        <div class="contact_wrapper">
          <h3>{t('contatti')}</h3>
        </div>
        <div class="contact_data">
          <div class="contact_item address_field">
            <h3>Filmar S.P.A.</h3>
            <p class="address">Via De Gasperi 6525030 Zocco d’Erbusco (BS) Italia</p>
            <p class="teliphone">
              <a href="tel:+39 030 776700">Tel: +39 030 776700</a>
            </p>
            <p class="fax">
              <a href="fax:+39 030 7760123">Fax: +39 030 7760123</a>
            </p>
            <div className=" footer_links contact">
              <div className="social">
                <ul>
                  <li>
                    <a href={socialMedia[0]?.facebook_link} target="_blank" className="facebook" />
                  </li>
                  <li>
                    <a href={socialMedia[0]?.instagram_link} target="_blank" className="instagram" />
                  </li>
                  <li>
                    <a href={socialMedia[0]?.linkedin_link} target="_blank" className="youtube" />
                  </li>
                </ul>
              </div>
            </div>
          </div>


          <div class="contact_item commerciale_field">
            <div class="item_data content_field">
              <div class="data_field">
                <h4>{t('direzione')}</h4>
                <p>
                  <a href="mailto:info@filmar.it">info@filmar.it</a>
                </p>
                <p>{t('richieste_generali')}</p>
              </div>
              <div class="data_field">
                <h4>{t('uff_coomerciale')}</h4>
                <p>
                  <a href="mailto:sales@filmar.it">sales@filmar.it</a>
                </p>
                <p>{t('richieste_vendita')}</p>
              </div>
              <div class="data_field">
                <h4>{t('contabilita_amministrazione')}</h4>
                <p>
                  <a href="mailto:finance@filmar.it">finance@filmar.it</a>
                </p>
                <p>{t('richieste_fatture')}</p>
              </div>
              <div class="data_field">
                <h4>{t('marketing_sostenibilita')}</h4>
                <p>
                  <a href="mailto:marketing@filmar.it">marketing@filmar.it</a>
                </p>
                <p>{t('richieste_marketing')}</p>
                <p>
                  <a href="mailto:sustainability@filmar.it">sustainability@filmar.it</a>
                </p>
                <p>{t('richieste_sostenibilita')}</p>
              </div>
              <div class="data_field">
                <h4>{t('supporto_web')}</h4>
                <p>
                  <a href="mailto:websupport@filmar.it">websupport@filmar.it</a>
                </p>
                <p>{t('richieste_assistenza_web')}</p>
              </div>
              <div class="data_field">
                <h4>{t('posizioni_lavorative')}</h4>
                <p>
                  <a href="mailto:jobposition@filmar.it">jobposition@filmar.it</a>
                </p>
                <p>{t('richieste_candidatura')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
      <div class="map_section">
        <div class="map_wrapper container">
          {/* <img src="/images/map_mobile.png" class="mobile_img" alt="mobile_map" /> */}
          <SimpleMap />
        </div>
      </div>
    </div>
  );
};

export default Contact;